<template>
  <div>
    <div id="wemap-header"></div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'OnlyHeaderLayout',
  mounted() {
    window.TWeMapHeader.init({
      container: document.getElementById('wemap-header'),
      theme: 'light',
    });
  },
  methods: {
  },
};
</script>
<style lang="less">
@import '@/styles/index.less';
</style>
