import { render, staticRenderFns } from "./only-header.vue?vue&type=template&id=81d2401a"
import script from "./only-header.vue?vue&type=script&lang=js"
export * from "./only-header.vue?vue&type=script&lang=js"
import style0 from "./only-header.vue?vue&type=style&index=0&id=81d2401a&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports